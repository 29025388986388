
import { defineComponent } from "vue";
import { Instigator, MutationInstigatorUpdateArgs } from "@/graphql/types";
import { gql } from "@apollo/client/core";
import { INSTIGATOR_FIELDS } from "@/graphql/instigator/instigators";
import { useValidation } from "vue3-form-validation";
import { useToast } from "primevue/usetoast";
import { useI18n } from "vue-i18n";
import { useMutation } from "@vue/apollo-composable";
import { trim } from "@/graphql/utils/utils";

type Data = {
  instigatorUpdate: Instigator;
};

const MUTATION = gql`
    mutation InstigatorUpdate($input: InstigatorUpdateInput!) {
        instigatorUpdate(input: $input) {
            ${INSTIGATOR_FIELDS}
        }
    }
`;

export default defineComponent({
  name: "InstigatorUpdate",
  props: ["instigator", "modelValue"],
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const { form, validateFields } = useValidation({
      id: {
        $value: props.instigator.id,
      },
      name: {
        $value: props.instigator.name,
        $rules: [(n: string) => !n && ""],
      },
    });
    const toast = useToast();
    const { t } = useI18n();
    const { loading, mutate, onDone } = useMutation<
      Data,
      MutationInstigatorUpdateArgs
    >(MUTATION);

    onDone(({ data }) => {
      const success = !!data?.instigatorUpdate;
      toast.add({
        severity: success ? "success" : "warn",
        summary: t("update.title"),
        detail: t(`update.${success ? "success" : "failed"}`),
        life: parseInt(process.env.VUE_APP_TOAST_LIFE),
      });
      emit("update:modelValue", false);
    });
    function submit() {
      validateFields().then((input) => {
        trim(input);
        void mutate({ input });
      });
    }
    return {
      loading,
      form,
      submit,
    };
  },
});
