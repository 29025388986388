
import { defineComponent, reactive } from "vue";
import { activeActivity } from "@/plugins/i18n";
import { numberSeparators } from "@/graphql/utils/utils";
import { useInstigators } from "@/graphql/instigator/instigators";
import InstigatorCreate from "@/components/instigator/InstigatorCreate.vue";
import InstigatorUpdate from "@/components/instigator/InstigatorUpdate.vue";
import { Instigator } from "@/graphql/types";
import { useInstigatorRemove } from "@/graphql/instigator/instigator-remove";

export default defineComponent({
  name: "Instigator",
  components: { InstigatorUpdate, InstigatorCreate },
  setup() {
    const instigatorUpdate = reactive({
      show: false,
      data: null,
    });
    function onClickEdit(data: Instigator) {
      instigatorUpdate.data = data;
      instigatorUpdate.show = true;
    }
    return {
      separator: numberSeparators[activeActivity.value.separator],
      ...useInstigators(),
      onClickEdit,
      instigatorUpdate,
      ...useInstigatorRemove(),
      makeLink: (id: number) =>
        process.env.VUE_APP_API + "account/sign-up/" + id,
    };
  },
});
