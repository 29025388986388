import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"margin":"0"} }
const _hoisted_2 = {
  for: "customer_name",
  class: "p-col-12"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Button, {
      type: "button",
      icon: "pi pi-plus",
      label: _ctx.$t('add'),
      class: "p-button-success",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.visible = true))
    }, null, 8, ["label"]),
    _createVNode(_component_Dialog, {
      visible: _ctx.visible,
      "onUpdate:visible": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.visible) = $event))
    }, {
      header: _withCtx(() => [
        _createElementVNode("h5", _hoisted_1, _toDisplayString(_ctx.$t("instigator.new")), 1)
      ]),
      footer: _withCtx(() => []),
      default: _withCtx(() => [
        _createElementVNode("form", {
          autocomplete: "off",
          spellcheck: "false",
          onSubmit: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args)), ["prevent"])),
          class: "p-grid"
        }, [
          _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.$t("customer.name")), 1),
          _createVNode(_component_InputText, {
            class: _normalizeClass(["p-col-12 p-mb-3", {
          'p-invalid': _ctx.form.name.$hasError,
        }]),
            id: "customer_name",
            placeholder: _ctx.$t('instigator.namePlaceholder'),
            type: "text",
            modelValue: _ctx.form.name.$value,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.name.$value) = $event)),
            onBlur: _cache[2] || (_cache[2] = ($event: any) => (_ctx.form.name.$validate()))
          }, null, 8, ["placeholder", "modelValue", "class"]),
          _createVNode(_component_Button, {
            type: "submit",
            disabled: _ctx.hasError || _ctx.loading,
            loading: _ctx.loading,
            style: {"height":"35px"},
            icon: "pi pi-plus",
            label: _ctx.$t('add'),
            class: "p-col-12"
          }, null, 8, ["disabled", "loading", "label"])
        ], 32)
      ]),
      _: 1
    }, 8, ["visible"])
  ], 64))
}