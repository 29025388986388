import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "p-field" }
const _hoisted_2 = { for: "name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    "onUpdate:visible": _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('update:modelValue', $event))),
    visible: _ctx.modelValue,
    header: _ctx.$t('update.title')
  }, {
    default: _withCtx(() => [
      _createElementVNode("form", {
        autocomplete: "off",
        spellcheck: "false",
        onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submit && _ctx.submit(...args)), ["prevent"])),
        class: "p-fluid"
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.$t("customer.name")), 1),
          _createVNode(_component_InputText, {
            id: "name",
            placeholder: _ctx.$t('instigator.namePlaceholder'),
            type: "text",
            modelValue: _ctx.form.name.$value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.name.$value) = $event)),
            onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.form.name.$validate())),
            class: _normalizeClass({
            'p-invalid': _ctx.form.name.$hasError,
          })
          }, null, 8, ["placeholder", "modelValue", "class"])
        ]),
        _createVNode(_component_Button, {
          class: "p-mt-2 p-button-outlined",
          type: "submit",
          icon: "pi pi-save",
          loading: _ctx.loading,
          disabled: _ctx.loading,
          label: _ctx.$t('save')
        }, null, 8, ["loading", "disabled", "label"])
      ], 32)
    ]),
    _: 1
  }, 8, ["visible", "header"]))
}