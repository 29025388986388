import { MutationInstigatorRemoveArgs } from "@/graphql/types";
import { gql } from "@apollo/client/core";
import { useToast } from "primevue/usetoast";
import { useI18n } from "vue-i18n";
import { useMutation } from "@vue/apollo-composable";
import { useConfirm } from "primevue/useconfirm";

type Data = {
  instigatorRemove: number;
};

const MUTATION = gql`
  mutation InstigatorRemove($id: Int!) {
    instigatorRemove(id: $id)
  }
`;

export const useInstigatorRemove = () => {
  const toast = useToast();
  const { t } = useI18n();
  const confirm = useConfirm();

  const {
    loading: loadingRemove,
    mutate,
    onDone,
    onError,
  } = useMutation<Data, MutationInstigatorRemoveArgs>(MUTATION, {
    update(cache, { data }) {
      const id = data?.instigatorRemove;
      if (id) {
        cache.modify({
          fields: {
            instigators(existingRef: any[], { readField }) {
              return existingRef.filter((eRef) => readField("id", eRef) !== id);
            },
          },
        });
      }
    },
  });

  onDone(({ data }) => {
    if (data) {
      toast.add({
        severity: "success",
        summary: t("delete.title"),
        detail: t("delete.success"),
        life: parseInt(process.env.VUE_APP_TOAST_LIFE),
      });
    } else {
      toast.add({
        severity: "warn",
        summary: t("delete.title"),
        detail: t(`customer.delete.failed`),
        life: parseInt(process.env.VUE_APP_TOAST_LIFE),
      });
    }
  });

  onError(() => {
    toast.add({
      severity: "warn",
      summary: t("customer.error.delete"),
      detail: t("networkError"),
      life: parseInt(process.env.VUE_APP_TOAST_LIFE),
    });
  });

  function submitRemove(event: any, id: number) {
    confirm.require({
      target: event.currentTarget,
      message: t("delete.confirm"),
      icon: "pi pi-exclamation-triangle",
      rejectLabel: t("no"),
      acceptLabel: t("yes"),
      acceptClass: "p-button-warning",
      accept: () => {
        void mutate({ id });
      },
    });
  }

  return { submitRemove, loadingRemove };
};
