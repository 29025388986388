
import { Instigator, MutationInstigatorCreateArgs } from "@/graphql/types";
import { defineComponent, ref } from "vue";
import { gql } from "@apollo/client/core";
import { INSTIGATOR_FIELDS } from "@/graphql/instigator/instigators";
import { useMutation } from "@vue/apollo-composable";
import { useValidation } from "vue3-form-validation";
import { useToast } from "primevue/usetoast";
import { useI18n } from "vue-i18n";
import { trim } from "@/graphql/utils/utils";

type Data = {
  instigatorCreate: Instigator;
};
const MUTATION = gql`
    mutation InstigatorCreate($input: InstigatorCreateInput!) {
        instigatorCreate(input: $input) {
            ${INSTIGATOR_FIELDS}
        }
    }
`;
export default defineComponent({
  name: "InstigatorCreate",
  setup() {
    const { loading, mutate, onDone } = useMutation<
      Data,
      MutationInstigatorCreateArgs
    >(MUTATION, {
      update: (cache, { data }) => {
        if (data?.instigatorCreate) {
          cache.modify({
            fields: {
              instigators(existingRef: any[], { toReference }) {
                return [toReference(data.instigatorCreate), ...existingRef];
              },
            },
          });
        }
      },
    });
    const toast = useToast();
    const { t } = useI18n();
    onDone(({ data }) => {
      if (data) {
        visible.value = false;
        toast.add({
          severity: "success",
          summary: t("create.success"),
          life: parseInt(process.env.VUE_APP_TOAST_LIFE),
        });
      } else {
        toast.add({
          severity: "error",
          summary: t("create.failed"),
          life: parseInt(process.env.VUE_APP_TOAST_LIFE),
        });
      }
    });
    const visible = ref(false);
    const { form, validateFields, hasError } = useValidation({
      name: {
        $value: "",
        $rules: [(n: string) => !n && ""],
      },
    });
    function submit() {
      validateFields().then((input) => {
        trim(input);
        void mutate({ input });
      });
    }
    return {
      visible,
      form,
      submit,
      loading,
      hasError,
    };
  },
});
