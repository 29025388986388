import { Instigator } from "@/graphql/types";
import { gql } from "@apollo/client/core";
import { useQuery, useResult } from "@vue/apollo-composable";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import { ref } from "vue";

type Data = {
  instigators: Instigator[];
};

export const INSTIGATOR_FIELDS = `
    id
    name
    createdAt
    users
`;
const QUERY = gql`
    query Instigators {
        instigators {
            ${INSTIGATOR_FIELDS}
        }
    }
`;
const FILTERS_ = {
  global: { value: "", matchMode: FilterMatchMode.CONTAINS },
  id: {
    operator: FilterOperator.AND,
    constraints: [{ value: [], matchMode: FilterMatchMode.IN }],
  },
  name: {
    operator: FilterOperator.AND,
    constraints: [{ value: "", matchMode: FilterMatchMode.STARTS_WITH }],
  },
  createdAt: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
  },
};
export const useInstigators = () => {
  const { loading, result } = useQuery<Data>(QUERY);

  const filters = ref(FILTERS_);
  function clearFilter() {
    Object.assign(filters.value, FILTERS_);
  }
  const instigators = useResult<Data, Instigator[], Instigator[]>(
    result,
    [],
    (res) =>
      res.instigators.map((ins) => ({
        ...ins,
        createdAt: new Date(ins.createdAt),
      }))
  );
  return {
    loading,
    instigators,
    clearFilter,
    filters,
  };
};
